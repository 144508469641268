import React from "react";
import "../styles/home.scss";
import "../styles/work.scss";
import { FaChevronLeft } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import { content } from "../utils/content";

export default class Portfolio extends React.Component {
  componentDidMount() {
    if (window.innerWidth >= 1366) {
      this.handleWorkClick(0);
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      activeWork: 0,
      rotation: 360,
      mobileWorkMenuClassList: "mobileWorkMenu",
      workClassList: "works",
      workContent: content[0],
      isChanging: false,
    };
  }

  handleWorkClick(number) {
    if (this.state.isChanging) return;

    document
      .getElementById("work" + this.state.activeWork)
      .classList.remove("currentWorkActive");

    this.setState({
      isChanging: true,
      activeWork: number,
      workClassList: "works worksActives",
      mobileWorkMenuClassList: "mobileWorkMenu mobileWorkMenuActive",
    });

    document.getElementById("work" + number).classList.add("currentWorkActive");

    document.getElementById("black-board").style.transform =
      "rotateY(" + this.state.rotation + "deg)";

    this.setState({ rotation: this.state.rotation + 360 });

    if (window.innerWidth < 1366) {
      this.setState({ workContent: content[number] });
    } else {
      const timer1 = setTimeout(() => {
        this.setState({ workContent: content[number] });
        document.getElementById("textContainer").style.opacity = 1;
      }, 1050);

      const timer2 = setTimeout(() => {
        document.getElementById("textContainer").style.opacity = 0;
      }, 200);

      const timer3 = setTimeout(() => {
        this.setState({ isChanging: false });
      }, 2000);

      return () => clearTimeout(timer1, timer2, timer3);
    }
  }

  render() {
    return (
      <div
        className="appContainer"
        id="work"
        style={{ justifyContent: "flex-start" }}
      >
        <div className={this.state.mobileWorkMenuClassList}>
          <div
            className="mobileWorkMenuInner"
            onClick={() =>
              this.setState({ mobileWorkMenuClassList: "mobileWorkMenu" })
            }
          >
            <FaChevronLeft />
          </div>
          <div className="mobileWorkMenuContent">{this.state.workContent}</div>
        </div>
        <div className="flexContainer1">
          <div
            className={this.state.workClassList}
            style={{ textAlign: "left" }}
          >
            <h1 id="work0" onClick={() => this.handleWorkClick(0)}>
              Unify
            </h1>
            <h1 id="work1" onClick={() => this.handleWorkClick(1)}>
              Lighthouse
            </h1>
            <h1 id="work2" onClick={() => this.handleWorkClick(2)}>
              SnapSmile
            </h1>
            <h1 id="work3" onClick={() => this.handleWorkClick(3)}>
              Dulcis
            </h1>
            <h1 id="work4" onClick={() => this.handleWorkClick(4)}>
              Dinos of Fury
            </h1>
          </div>
        </div>
        <div className="flexContainer2">
          <div className="description" style={{ textAlign: "left" }}>
            <Tilt
              className="Tilt"
              glareEnable={true}
              glareColor="rgba(225, 225, 225, 0.2)"
              scale={1.05}
              perspective={2000}
              tiltMaxAngleX={12}
              tiltMaxAngleY={12}
              transitionSpeed={2000}
              trackOnWindow={true}
            >
              <div id="black-board">
                <div id="textContainer">{this.state.workContent}</div>
              </div>
            </Tilt>
          </div>
        </div>
      </div>
    );
  }
}
