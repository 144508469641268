import { FaArrowRight } from "react-icons/fa";

export const content = [
  <div>
    <div>
      <h2>Seamless Design to Code</h2>
      <p>
        Launched a Figma plugin and comprehensive design tool automating UI
        design conversion to React, HTML, and React Native code, garnering over
        35,000 installations. Recognized for startup potential, earning a spot
        in Pioneer's prestigious 2023 winter batch startup accelerator program.
      </p>
      <a href="https://www.useunify.app/" rel="noreferrer" target="_blank">
        <div className="background-anim"></div>Launch Project &nbsp;
        <FaArrowRight />
      </a>
      <div className="stats">
        <div>
          <h4>ROLE</h4>
          <h6>Co-Founder</h6>
        </div>
        <div>
          <h4>SKILLS</h4>
          <h6>React.js, Express.js, Firebase, Figma</h6>
        </div>
      </div>
    </div>
  </div>,
  <div>
    <div>
      <h2>Modernizing Legal Workflows</h2>
      <p>
        Developed an AI-driven immigration petition preparation platform as part
        of a 3-person team using React, GraphQL, PostgreSQL, and Python. Built a
        PDF rendering engine, web-based form editor, and a collaborative text
        editor with version control, commenting, and AI rewriting.
      </p>
      <a href="https://www.lighthousehq.com/" rel="noreferrer" target="_blank">
        <div className="background-anim"></div>Launch Project &nbsp;
        <FaArrowRight />
      </a>
      <div className="stats">
        <div>
          <h4>ROLE</h4>
          <h6>Software Engineer</h6>
        </div>
        <div>
          <h4>SKILLS</h4>
          <h6>React.js, GraphQL, PostgreSQL, Python</h6>
        </div>
      </div>
    </div>
  </div>,
  <div>
    <div>
      <h2>Redefining Dental Care</h2>
      <p>
        Played a key role in developing an innovative mobile dental health
        platform, showcased on Dragons' Den. This app guides users in capturing
        images of their teeth, utilizing AI to analyze dental health and
        generate detailed reports, transforming the way users access dental case
        remotely.
      </p>
      <a href="https://www.snapsmile.ai/" rel="noreferrer" target="_blank">
        <div className="background-anim"></div>Launch Project &nbsp;
        <FaArrowRight />
      </a>
      <div className="stats">
        <div>
          <h4>ROLE</h4>
          <h6>Mobile Engineer</h6>
        </div>
        <div>
          <h4>SKILLS</h4>
          <h6>React Native, Typescript, Firebase, Figma</h6>
        </div>
      </div>
    </div>
  </div>,
  <div>
    <div>
      <h2>Reimagining Diabetes Care</h2>
      <p>
        Dulcis is a hackathon award-winning AI-powered mobile application to
        transform diabetes management. Combining gamification and a virtual
        assistant, it enables users to analyze meal images for glycemic index,
        track blood sugar levels, and receive personalized support, all within
        an intuitive platform.
      </p>
      <a
        href="https://www.youtube.com/watch?v=PdTgYJRXTSU"
        rel="noreferrer"
        target="_blank"
      >
        <div className="background-anim"></div>Launch Project &nbsp;
        <FaArrowRight />
      </a>
      <div className="stats">
        <div>
          <h4>ROLE</h4>
          <h6>Creator</h6>
        </div>
        <div>
          <h4>SKILLS</h4>
          <h6>React Native, JavaScript, Firebase</h6>
        </div>
      </div>
    </div>
  </div>,
  <div>
    <div>
      <h2>Dino Battle Royale</h2>
      <p>
        Dinos of Fury is a fun 2D top-down shooter game where you play as a
        dinosaur with special abilities and the goal to survive waves of
        monsters for as long as possible. The game was created with Javascript
        and HTML5 canvas, implementing animated sprites, game animations, and an
        eye-catching pixelated art style.
      </p>
      <a
        href="https://eshchock1.github.io/Dinos-of-Fury/"
        rel="noreferrer"
        target="_blank"
      >
        <div className="background-anim"></div>Launch Project &nbsp;
        <FaArrowRight />
      </a>
      <div className="stats">
        <div>
          <h4>ROLE</h4>
          <h6>Creator</h6>
        </div>
        <div>
          <h4>SKILLS</h4>
          <h6>Javascript, HTML5, CSS</h6>
        </div>
      </div>
    </div>
  </div>,
];
